var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pay_product_wrap" }, [
    _c("div", { staticClass: "pay_product_content" }, [
      _c("div", { staticClass: "pay_product_images_wrap" }, [
        _c("div", { staticClass: "pay_product_images" }, [
          _c("div", { staticClass: "pay_product_images_swiper_wrap swiper" }, [
            _c(
              "div",
              { staticClass: "swiper-wrapper" },
              _vm._l(_vm.imageList, function (pic, i) {
                return _c(
                  "div",
                  {
                    key: pic.id,
                    staticClass: "pay_product_image swiper-slide",
                    class: {
                      "pay_product_image--video detail_inner_wrap":
                        i === 0 && _vm.productDetailVm.showVideo,
                    },
                    on: {
                      click: () =>
                        !(i === 0 && _vm.productDetailVm.showVideo) &&
                        _vm.photoSlide(i),
                    },
                  },
                  [
                    _c("img", { attrs: { src: pic.path } }),
                    _vm._v(" "),
                    i === 0 && _vm.productDetailVm.showVideo
                      ? _c(
                          "div",
                          {
                            staticClass: "bf",
                            attrs: { title: "播放视频" },
                            on: {
                              click: function ($event) {
                                return _vm.productDetailVm.getProductVideo(
                                  ".pay_product_image--video"
                                )
                              },
                            },
                          },
                          [_c("div", { staticClass: "bfDiv" })]
                        )
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.showImagesIndicator
          ? _c("div", { staticClass: "pay_product_images_indicator_wrap" }, [
              _vm.imageCountMoreThan4
                ? _c("div", {
                    staticClass: "pay_product_images_indicator_prev",
                    on: {
                      click: function ($event) {
                        return _vm.changeImageActiveIndex(
                          _vm.imageActiveIndex - 1
                        )
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "pay_product_images_indicator_swiper_wrap",
                  class: { swiper: _vm.imageCountMoreThan4 },
                },
                [
                  _c(
                    "div",
                    {
                      class: [
                        _vm.imageCountMoreThan4
                          ? "swiper-wrapper"
                          : "pay_product_image_indicator_content",
                      ],
                    },
                    _vm._l(_vm.imageList, function (pic, i) {
                      return _c(
                        "div",
                        {
                          key: pic.id,
                          class: [
                            _vm.imageCountMoreThan4
                              ? "swiper-slide"
                              : "pay_product_image_indicator_wrap",
                          ],
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "pay_product_image_indicator",
                              class: {
                                "pay_product_image_indicator--active":
                                  i === _vm.imageActiveIndex,
                              },
                              on: {
                                mouseover: function ($event) {
                                  return _vm.changeImageActiveIndex(i)
                                },
                              },
                            },
                            [_c("img", { attrs: { src: pic.path } })]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.imageCountMoreThan4
                ? _c("div", {
                    staticClass: "pay_product_images_indicator_next",
                    on: {
                      click: function ($event) {
                        return _vm.changeImageActiveIndex(
                          _vm.imageActiveIndex + 1
                        )
                      },
                    },
                  })
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isMobi && _vm.imageList.length > 0
          ? _c("div", { staticClass: "mobi_detail_page" }, [
              _vm._v(
                _vm._s(_vm.imageActiveIndex + 1) +
                  "/" +
                  _vm._s(_vm.imageList.length)
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pay_product_info" },
        [
          _c("div", { staticClass: "pay_product_title" }, [
            _c("span", [_vm._v(_vm._s(_vm.productInfo.name))]),
            _vm._v(" "),
            _vm.isMobi && _vm.advisoryInfo.openProductAdvisory
              ? _c(
                  "div",
                  {
                    staticClass: "pay_product_consult_in_mobi",
                    on: { click: _vm.handleAdvisoryClick },
                  },
                  [
                    _c("svg", [
                      _c("use", { attrs: { "xlink:href": "#icon_consult" } }),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.module.isOpenPdDesc
            ? _c("div", {
                staticClass: "pay_product_desc",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.encodeHtmlStr(_vm.productInfo.salePointDesc)
                  ),
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isShowSales
            ? _c("div", { staticClass: "pay_product_sales_wrap" }, [
                _c("div", { staticClass: "pay_product_sales_bg" }),
                _vm._v(" "),
                _c("span", { staticClass: "pay_product_sales" }, [
                  _vm._v(_vm._s(_vm.LS.soldOut) + " " + _vm._s(_vm.sales)),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isShowPrice || _vm.isShowMarketPrice
            ? _c(
                "div",
                { staticClass: "pay_product_price_wrap" },
                [
                  _vm.isShowPrice
                    ? _c("Price", {
                        staticClass: "pay_product_price",
                        attrs: {
                          price: _vm.formatPrice,
                          currency: _vm.choiceCurrencyVal,
                          "currency-position-right": _vm.currencyPositionRight,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isShowMarketPrice
                    ? _c("Price", {
                        staticClass: "pay_product_market_price",
                        attrs: {
                          price: _vm.formatMarketPrice,
                          currency: _vm.choiceCurrencyVal,
                          "currency-position-right": _vm.currencyPositionRight,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showSplitLineInPc
            ? _c("div", { staticClass: "split_line" })
            : _vm._e(),
          _vm._v(" "),
          _vm.showParamsInPc
            ? _c("ProductParams", {
                staticClass: "pay_product_params_wrap",
                attrs: { list: _vm.showPropList },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showSplitLineInPc && _vm.showParamsInPc
            ? _c("div", { staticClass: "split_line" })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "pay_product_count_wrap" }, [
            _c("div", { staticClass: "pay_product_count_title" }, [
              _vm._v(_vm._s(_vm.LS.orderListItemPdAmount)),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pay_product_count" },
              [
                _c("Counter", {
                  attrs: { size: _vm.isMobi ? "middle" : "default" },
                  model: {
                    value: _vm.count,
                    callback: function ($$v) {
                      _vm.count = $$v
                    },
                    expression: "count",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "pay_product_btn_wrap_line",
            staticStyle: { height: "1px" },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "pay_product_btn_wrap",
              class: { "pay_product_buy_btn--fixed": _vm.btnFixedInMobi },
            },
            [
              _vm.commerceSettings.openShoppingCart &&
              _vm.commerceSettings.openECommerce
                ? [
                    _vm.isMobi && _vm.btnFixedInMobi
                      ? _c(
                          "div",
                          {
                            staticClass: "pay_product_cart_icon",
                            on: { click: _vm.gotoCart },
                          },
                          [
                            _c("svg", [
                              _c("use", {
                                attrs: { "xlink:href": "#icon_cart" },
                              }),
                            ]),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "pay_product_cart",
                            on: { click: _vm.gotoCart },
                          },
                          [_vm._v(_vm._s(_vm.commerceSettings.cartBtnText))]
                        ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "pay_product_buy_btn",
                  on: { click: _vm.gotoBuy },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.commerceSettings.purchaseBtnText) +
                      "\n                "
                  ),
                ]
              ),
            ],
            2
          ),
          _vm._v(" "),
          _vm.showFunctionsInPc
            ? _c("div", { staticClass: "pay_product_functions_wrap" }, [
                _vm.advisoryInfo.openProductAdvisory
                  ? _c(
                      "div",
                      {
                        staticClass: "pay_product_func_wrap",
                        on: { click: _vm.handleAdvisoryClick },
                      },
                      [
                        _c("svg", [
                          _c("use", {
                            attrs: { "xlink:href": "#icon_consult" },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.advisoryInfo.advisoryWay == 0
                                ? _vm.advisoryInfo.advisoryPhone
                                : _vm.advisoryInfo.advisoryTxt
                            )
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showDownload
                  ? _c(
                      "a",
                      {
                        staticClass: "pay_product_func_wrap",
                        attrs: { href: _vm.downloadUrl },
                        on: { click: _vm.downloadClick },
                      },
                      [
                        _c("svg", [
                          _c("use", { attrs: { "xlink:href": "#icon_file1" } }),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(_vm._s(_vm.downloadModel.downloadBtnText)),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.qrFlag && !_vm.flagQrCode
                  ? _c(
                      "div",
                      { staticClass: "pay_product_func_wrap detail_QRcode" },
                      [
                        _c("svg", [
                          _c("use", {
                            attrs: { "xlink:href": "#icon_qrcode" },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(_vm.module.qrcode))]),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.flagShare
            ? [
                _vm.showSplitLineInPc
                  ? _c("div", { staticClass: "split_line" })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "pay_product_share_wrap" },
                  [
                    _c("JzShareSsrCompon", {
                      attrs: {
                        version: "v2",
                        "share-param-list": _vm.shareParamList,
                      },
                      on: { "share-to-pinterest": _vm.shareToPinterest },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isMobi && _vm.showParams && !_vm.flagShare
            ? _c(
                "div",
                { staticClass: "pay_product_params_in_mobi" },
                [
                  _c(
                    "Fold",
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.LS.mobi_productParameter)),
                      ]),
                      _vm._v(" "),
                      _c("ProductParams", {
                        staticClass: "pay_product_params_wrap",
                        attrs: { list: _vm.showPropList },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isMobi && _vm.showDownload
            ? _c(
                "div",
                { staticClass: "pay_product_download_wrap_in_mobi" },
                [
                  _c("Fold", [
                    _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                      _vm._v(_vm._s(_vm.downloadModel.downloadBtnText)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "pay_product_download_in_mobi",
                        attrs: { href: _vm.downloadUrl },
                        on: { click: _vm.downloadClick },
                      },
                      [
                        _c("div", [_vm._v(_vm._s(_vm.downloadModel.fileName))]),
                        _vm._v(" "),
                        _c("svg", [
                          _c("use", { attrs: { "xlink:href": "#download" } }),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _vm.showNavigatorInPc && _vm.productPrevAndNextInfo && !_vm.flagPrevNext
      ? _c("div", { staticClass: "pay_product_navigator" }, [
          _vm.productPrevAndNextInfo.hasPrev
            ? _c(
                "a",
                {
                  staticClass: "pay_product_prev",
                  attrs: {
                    onclick: _vm.productPrevAndNextInfo.prevProductUrlCookie,
                    href: _vm.productPrevAndNextInfo.prevProductUrl,
                  },
                },
                [
                  _c("svg", [
                    _c("use", { attrs: { "xlink:href": "#icon_prev" } }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pay_product_navigator_text" }, [
                    _vm._v(
                      _vm._s(_vm.LS.productPrevOne) +
                        ": " +
                        _vm._s(_vm.productPrevAndNextInfo.prevProductName)
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.productPrevAndNextInfo.hasPrev &&
          _vm.productPrevAndNextInfo.hasNext
            ? _c("div", { staticClass: "pay_product_navigator_line" })
            : _vm._e(),
          _vm._v(" "),
          _vm.productPrevAndNextInfo.hasNext
            ? _c(
                "a",
                {
                  staticClass: "pay_product_next",
                  attrs: {
                    onclick: _vm.productPrevAndNextInfo.nextProductUrlCookie,
                    href: _vm.productPrevAndNextInfo.nextProductUrl,
                  },
                },
                [
                  _c("div", { staticClass: "pay_product_navigator_text" }, [
                    _vm._v(
                      _vm._s(_vm.LS.productNextOne) +
                        ": " +
                        _vm._s(_vm.productPrevAndNextInfo.nextProductName)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("svg", [
                    _c("use", { attrs: { "xlink:href": "#icon_next" } }),
                  ]),
                ]
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }