import { Module } from '@shared/visitor/module/index.js';
import { successMessage, warningMessage } from '@/site/shared/dialog/index.js';
import { getPageScrollTarget } from '@/site/shared/index.js';
import {
    bindShareMouseenter,
    triggerSharePanel,
    bindShareMouseleave,
} from '@/components/modules/common/shareComponent/index.js';
import { context as globalContext } from '@jz/utils';
const LS = globalContext.LS || {};
import { PayProduct } from '@/services';
/**
 * 产品详情
 */
export const productDetail = class ProductDetail extends Module {
    constructor(...args) {
        super(...args);
        this.vueInstance = null;
        this.init();
    }

    init() {
        var productInfo = this.conf.productInfo,
            _this = this;

        if (!productInfo) {
            return;
        }
        const $productShare = $(`#productShare${this.moduleId}`);
        // if ($('#comment_wrapper').length > 0) {

        // 	_this.vueInstance = new Vue({
        // 		el:'#comment_wrapper',
        // 		template: `<moduleProductComment
        // 						cmd='getProductComment'
        // 						ref='productComment'
        // 						:productId='${productInfo.id}'
        // 						:moduleId='${_this.moduleId}'
        // 					/>`
        // 	});
        // };

        // 产品详情面板切换
        this.$el.off('click.s').on('click.s', '.detail_tab_item', (e) => {
            var $target = $(e.currentTarget);
            e.stopPropagation();
            this.switchSpecificTab($target);
        });

        this.$el.off('click.z').on('click.z', '.menber_small_pic_wrap', (e) => {
            e.stopPropagation();
            var $target = $(e.currentTarget);
            this.zoomPreviewImg($target);
        });

        this.commentImgFileUploadH5('evaluate_upload_pic'); // 评论图片上传

        this.$el.off('click.d').on('click.d', '.del_upload_pic', (e) => {
            e.stopPropagation();
            var $target = $(e.currentTarget);
            this.delCommentUploadPic($target);
        });

        this.$el.off('click.l').on('click.l', '.detail_member_login', (e) => {
            e.stopPropagation();
            var $target = $(e.currentTarget);
            this.goLogin($target);
        });

        this.$el.off('click.e').on('click.e', '.evaluate_star i', (e) => {
            e.stopPropagation();
            var $target = $(e.currentTarget);
            this.setCommentStar($target);
        });

        // 刷新验证码
        this.$el.off('click.i').on('click.i', '.evaluate_code_img', (e) => {
            e.stopPropagation();
            $(e.currentTarget).attr('src', '/validateCode.jsp?' + Math.random() * 1000 + '&validateCodeRegType=3');
        });
        this.$el.find('.evaluate_code_img').click();

        // 提交评论
        // this.$el.off("click.c").on("click.c", ".commit_comment_btn", e => {
        // 	e.stopPropagation();
        // 	var $target = $(e.currentTarget);
        // 	// this.productCommentAddCom($target);
        // });

        // 小预览图
        this.$el.off('mouseenter.m').on('mouseenter.m', '.detail_small_pic_list', (e) => {
            e.stopPropagation();
            var $target = $(e.currentTarget);
            this.selectSmallPic($target);
            this.arrowSmallPic();
        });

        // 二维码展示
        if (productInfo.ptQRCodeOpen) {
            this.showProductQRCode(productInfo.ptQRcodeURl);
            this.$el.off('mouseenter.q').on('mouseenter.q', '.detail_QRcode', (e) => {
                e.stopPropagation();
                var $target = $(e.currentTarget),
                    _position = $target.position(),
                    QRCode = $('.detail_QR_code');

                // 产品详情样式3的时候才需要二维码定位逻辑
                if (this.conf.prop0 == 2) {
                    let position = $('.product_detail_right_content').css('position');
                    var qrLeft =
                        this.$el.width() -
                        $('.product_detail_right_content').outerWidth(true) -
                        $target.width() * 4 +
                        _position.left +
                        'px';
                    var qrTop =
                        $('.product_detail_right_content').offset().top -
                        this.$el.offset().top +
                        $target.height() * 1.5 +
                        'px';
                    if (position == 'absolute' || position == 'fixed') {
                        QRCode.css({
                            left: qrLeft,
                            top: qrTop,
                        }).fadeIn();
                    } else if (position == 'static') {
                        QRCode.css({
                            left: _position.left - $target.width() * 2 + 'px',
                            top: _position.top + $target.height() * 2.5 + 'px',
                        }).fadeIn();
                    }

                    return;
                }

                let left = _position.left - $target.width() + 'px';
                let top = _position.top + $target.height() + 'px';

                if (
                    PayProduct.isPayProduct(
                        productInfo,
                        window._store.state.modules.module4.commerceSettings?.openECommerce ?? false
                    )
                ) {
                    left = _position.left - (QRCode.width() - $target.width()) / 2 + 'px';
                    top = _position.top - QRCode.height() - 10 + 'px';
                }

                QRCode.css({
                    left,
                    top,
                }).fadeIn();
            });
            this.$el.off('mouseout.o').on('mouseout.o', '.detail_QRcode', (e) => {
                e.stopPropagation();
                $('.detail_QR_code').fadeOut();
            });
        }

        this.$el.off('mouseenter.sl').on('mouseenter.sl', '.detail_share', (e) => {
            e.stopPropagation();
            if (window.innerWidth < 768) {
                return;
            }
            var $target = $(e.currentTarget),
                _offset = $target.offset(),
                shareDom = $productShare.find('.jz_share_list');
            if (Comm.getDevice() != 'mobi') {
                shareDom.css({
                    left: _offset.left + $target.width() + 30 + 'px',
                    top: _offset.top + $target.height() + 10 - $(window).scrollTop() + 'px',
                    position: 'fixed',
                    transform: 'translateX(-100%)',
                });
            } else {
                shareDom.css({
                    left: '',
                    top: '',
                    position: '',
                    transform: '',
                });
            }
        });

        this.$el.off('click.jzs').on('click.jzs', '.jz_share_info ', (e) => {
            triggerSharePanel(e, `#productShare${this.moduleId}`);
        });

        this.$el.off('click.sp').on('click.sp', '.small_pic_prev ', (e) => {
            e.stopPropagation();
            this.arrowSmallPic('prev');
        });
        this.$el.off('click.sn').on('click.sn', '.small_pic_next ', (e) => {
            e.stopPropagation();
            this.arrowSmallPic('next');
        });

        // mobi下，展开产品参数
        // this.$el.off("click.do").on("click.do", ".detail_other_message_icon_for_mobi", e => {
        // 	$(".detail_other_message_mobi_wrap").show();
        // 	$(".detail_other_message_mobi").animate({
        // 	    height: "80%",
        // 	  }, 500 );
        // 	// 点击这个分享的时候，隐藏掉返回顶部按钮
        // 	JZ.hideBackToTop();
        // });

        // this.$el.off("click.mb").on("click.mb", ".detail_other_message_mobi_btn", e => {
        // 	$(".detail_other_message_mobi").animate({
        // 	    height: "0",
        // 	  }, 500 , function(){
        // 		  $(".detail_other_message_mobi_wrap").hide();
        // 		  JZ.showBackToTop();
        // 	  });
        // 	$('body').css({"position":"static"});
        // });

        this.$el.off('click.dcm').on('click.dcm', '.detail_comment_mobi ', () => {
            var key = 'productDetailGoLogin';
            var val = $.cookie(key) || 0;
            $.cookie('productDetailGoLogin', val | 0x2, { path: '/' });
            $('body').addClass('jz_body_no_scroll');
            $('.detail_evaluate_panel').fadeIn();
        });
        this.$el.off('click.dep').on('click.dep', '.detail_evaluate_panel ', () => {
            $('body').removeClass('jz_body_no_scroll');
            $('.detail_evaluate_panel').fadeOut();
        });
        this.$el.off('click.depm').on('click.depm', '.detail_evaluate_panel_mobi ', (e) => {
            e.stopPropagation();
        });

        let timer = null;
        $(this.$el)
            .find('.jz_share_info')
            .off('mouseenter.share')
            .on('mouseenter.share', function (e) {
                if (e?.target && Array.from(e?.target?.classList)?.includes('jz_share_list')) {
                    clearTimeout(timer);
                    timer = null;
                    return;
                }
                bindShareMouseenter($(this));
                $(this)
                    .off('mouseleave.share')
                    .on('mouseleave.share', function () {
                        timer = setTimeout(() => {
                            bindShareMouseleave($(this));
                        }, 300);
                    });
            });

        this.curIndex = 0;
        this.$el
            .find('.detail_inner_wrap')
            .off('swipeleft.l')
            .on('swipeleft.l', '.mobi_detail_big_pic_content:visible', function () {
                _this.nextClick(); // 向右划动事件
            });
        this.$el
            .find('.detail_inner_wrap')
            .off('swiperight.r')
            .on('swiperight.r', '.mobi_detail_big_pic_content:visible', function () {
                _this.prevClick(); // 向左划动事件
            });
        this.$el
            .find('.detail_specific_tab_wrap')
            .off('swipeleft.tl')
            .on('swipeleft.tl', '.tab_item_4, .tab_item_5, .tab_item_6', { dir: 'left' }, function (e) {
                _this.slideTabItem(this, e.data.dir);
            });
        this.$el
            .find('.detail_specific_tab_wrap')
            .off('swiperight.tr')
            .on('swiperight.tr', '.tab_item_4, .tab_item_5, .tab_item_6', { dir: 'right' }, function (e) {
                _this.slideTabItem(this, e.data.dir);
            });
        $(function () {
            var key = 'productDetailGoLogin';
            var val = $.cookie(key) || 0;
            if (val) {
                if ((val & 1) == 1) {
                    $('.J_product_detail_tab_comment').click();
                    if ((val & 2) == 2) {
                        $('.detail_comment_mobi').click();
                    }
                }
                $.cookie(key, '', { path: '/', expires: -1 });
            }
        });
    }

    slideTabItem(dom, dir) {
        var $dom = $(dom),
            $child = $dom.find('.detail_tab_item'),
            thisWidth = $dom.width(),
            childWidth = $child.outerWidth(),
            thisLeft = $dom.position().left,
            len = $child.length,
            parentWidth = childWidth * len;
        if (thisLeft == 0 && dir == 'left') {
            $dom.css('left', thisWidth - parentWidth + 'px');
        } else if (thisLeft < 0 && dir == 'right') {
            $dom.css('left', 0);
        }
    }

    slide(to, direction, currentIndex) {
        var _this = this;
        this.curIndex = currentIndex != undefined ? currentIndex : this.curIndex;
        if (this.curIndex == to) return;
        if ($('.mobi_detail_big_pic_item').is(':animated')) return false;

        direction = direction || Math.abs(to - this.curIndex) / (to - this.curIndex); // 1: backward, -1: forward

        $(function () {
            var $curItem = $('.mobi_detail_big_pic_item' + _this.curIndex),
                $toItem = $('.mobi_detail_big_pic_item' + to),
                dir = direction == -1 ? '-' : '',
                allDir = direction == -1 ? '+=' : '-=';

            $('.mobi_detail_big_pic_item').hide();
            $toItem.css('left', dir + '100%').show();
            $curItem.show();
            $('.mobi_detail_big_pic_item')
                .removeClass('item_actived')
                .animate(
                    { left: allDir + '100%' },
                    {
                        duration: 300,
                    }
                );
            _this.curIndex = to;
            $('.mobi_detail_page').text(to + 1 + '/' + $('.mobi_detail_big_pic_item').length);
            $('.mobi_detail_big_pic_item' + to).addClass('item_actived');
        });
    }

    nextClick() {
        var picLen = $('.mobi_detail_big_pic_item').length;

        if ($('.mobi_detail_big_pic_item').is(':animated') || picLen <= 1) {
            return false;
        }
        var to = 0;
        if (this.curIndex == picLen - 1) {
            // 上限
            this.curIndex = -1;
        }
        to = this.curIndex + 1;
        this.curIndex = to == 0 ? picLen - 1 : this.curIndex;
        this.slide(to, 1, this.curIndex);
        //兼容产品播放视频
        var bf = $('.detail_inner_wrap .mobi_detail_big_pic_content .bf');
        if (bf.length == 1 && this.curIndex != 0) {
            $(bf).hide();
        } else {
            $(bf).show();
        }
    }

    prevClick() {
        var picLen = $('.mobi_detail_big_pic_item').length;

        if ($('.mobi_detail_big_pic_item').is(':animated') || picLen <= 1) {
            return false;
        }
        var to = 0;
        if (this.curIndex == 0) {
            // 上限
            this.curIndex = picLen;
        }
        to = this.curIndex - 1;
        this.curIndex = to == picLen - 1 ? 0 : this.curIndex;
        this.slide(to, -1, this.curIndex);
        //兼容产品播放视频
        var bf = $('.detail_inner_wrap .mobi_detail_big_pic_content .bf');
        if (bf.length == 1 && this.curIndex != 0) {
            $(bf).hide();
        } else {
            $(bf).show();
        }
    }

    switchSpecificTab($target) {
        // 详情、评论面板 切换
        var toPanel = $target.attr('_topanel'),
            activeIndex = $target.index() + 1,
            itemWidth = $target.width(),
            targetWidth = itemWidth * activeIndex,
            visualWidth = itemWidth * 3,
            $targetParent = $('.detail_specific_tab'),
            $toPanel = this.$el.find('.' + toPanel);
        $toPanel.slideDown('fast');
        $toPanel.siblings().slideUp('fast');
        if (targetWidth >= visualWidth) {
            this.slideTabItem($targetParent, 'left');
        } else {
            this.slideTabItem($targetParent, 'right');
        }
        //this.$el.find("."+ toPanel).fadeIn().siblings().fadeOut();
        $target
            .addClass('detail_tab_active jz_theme_font_color')
            .siblings()
            .removeClass('detail_tab_active jz_theme_font_color');

        // 切换时滚动到面板顶部（防止被遮挡）
        getPageScrollTarget().scrollTop +=
            $('.product_detail_specific_content').offset().top - $('.detail_specific_tab_wrap').offset().top;
    }

    zoomPreviewImg($target) {
        var $view = $target.siblings('.menber_pic_viewer').find('img'),
            viewSrc = '';

        if (!$target.hasClass('detail_zoom_img')) {
            viewSrc = $target.find('img').attr('view');
            $target
                .addClass('detail_zoom_img jz_theme_border_color')
                .siblings()
                .removeClass('detail_zoom_img jz_theme_border_color');
            $view.show();
        } else {
            $target.removeClass('detail_zoom_img jz_theme_border_color');
            $view.hide();
        }
        $view.attr('src', viewSrc);
    }

    //  评论图片上传 HTML5
    commentImgFileUploadH5(placeHolderId) {
        if ($('#' + placeHolderId).length < 1) {
            return;
        }
        var conf = this.conf.productInfo,
            fileSize = conf.COMMENT_UP_IMG_MAX_SIZE,
            fileTypeString = conf.COMMENT_UP_IMG_TYPE_VALId,
            upMaxNum = conf.COMMENT_UP_IMG_MAX_NUM;

        var fileTypeList = fileTypeString.split(',');

        var advance_setting = {
            siteFree: false, //是否为免费版
            updateUrlViewRes: '', //升级目标版本URL
            auto: true,
            fileTypeExts: fileTypeList.join(';'),
            multi: false,
            fileSizeLimit: fileSize * 1024 * 1024,
            fileSplitSize: 20 * 1024 * 1024,
            breakPoints: true,
            saveInfoLocal: false,
            showUploadedPercent: false, //是否实时显示上传的百分比，如20%
            showUploadedSize: false,
            removeTimeout: 9999999,
            post_params: {
                app: 21,
                type: 0,
                fileUploadLimit: fileSize,
                isSiteForm: true,
            },
            isBurst: false, //表单先不分片,因为断点续传后台还不支持。到时可以了再去掉
            isDefinedButton: true,
            buttonText: '',
            uploader: '/ajax/commUpsiteimg_h.jsp?cmd=wafNotCk_mobiUpload&_TOKEN=' + $('#_TOKEN').attr('value'),
            onUploadSuccess(file, text) {
                var data = jQuery.parseJSON(text);
                if (data.success) {
                    onFileUploadEvent('upload', data);
                    setTimeout(function () {
                        successMessage(LS.fileUploadSuccess, true);
                    }, 2000);
                } else {
                    warningMessage(LS.files + ':' + file.name + '，' + data.msg, true);
                }
            },
            onUploadError(file) {
                $('#progressBody_ ' + file.id).remove();
                $('#progressWrap_' + file.id).remove();
                warningMessage(Fai.format(LS.mobiFormSubmitFileUploadSvrBusy, file.name), true);
            },
            onSelect() {
                // 上传之前检查是否超过限制
                if (upMaxNum == null || typeof upMaxNum == 'undefined') {
                    upMaxNum = 5;
                }
                let uploadList = $('.evaluate_upload_pic_lists .upload_pic_item');
                if (uploadList.length >= upMaxNum) {
                    warningMessage(LS.siteFormSubmitFileUploadOfMax, true);
                    return false;
                }
                return true;
            },
            onUploadStart(file) {
                $('#progressBody_ ' + file.id).remove();
                $('#progressWrap_' + file.id).remove();
            },
        };
        $('#' + placeHolderId).uploadify(advance_setting);
        function onFileUploadEvent(evt, args) {
            if (evt == 'upload') {
                var tmp_file_id = args.id; //返回临时文件ID
                var file_name = args.name; //返回文件名
                var file_size = args.size; //返回文件大小
                var file_small_path = args.pathSmall; //返回小图片的图片的临时目录
                var fileId = args.fileId; //返回文件fileId
                var file_width = args.width || 0;
                var file_height = args.height || 0;
                var file_type = args.type || 0;
                showCommentUploadPic(
                    file_small_path,
                    file_name,
                    tmp_file_id,
                    file_size,
                    upMaxNum,
                    fileId,
                    file_width,
                    file_height,
                    file_type
                );
            }
        }

        function showCommentUploadPic(
            path,
            name,
            imgId,
            file_size,
            upMaxNum,
            fileId,
            file_width,
            file_height,
            file_type
        ) {
            var picLists = $('.evaluate_upload_pic_lists'),
                len = 0,
                picDomStr = '';

            picDomStr += "<li class='upload_pic_item'>";
            picDomStr += "<span class='del_upload_pic'></span>";
            picDomStr +=
                "<div class='upload_pic_wrap'><img class='upload_img self_adaption_img position_center_middle' src='" +
                path +
                "' _name='" +
                name +
                "' _id='" +
                imgId +
                "' _file_size='" +
                file_size +
                "' _file_id='" +
                fileId +
                "'  _fileWidth='" +
                file_width +
                "'  _fileHeight='" +
                file_height +
                "'  _fileType='" +
                file_type +
                "'></div>";
            picDomStr += '</li>';

            picLists.prepend(picDomStr);
            len = picLists.find('li').length;
            $('.upload_total').html(len);
            $('.upload_remain').html(upMaxNum - len);
        }
    }

    delCommentUploadPic(_this) {
        var $parent = _this.parent(),
            picLists = $('.evaluate_upload_pic_lists'),
            len = 0;

        $parent.remove();
        len = picLists.find('li').length;
        $('.upload_total').html(len);
        $('.upload_remain').html(this.conf.productInfo.COMMENT_UP_IMG_MAX_SIZE - len);
    }

    //去登录
    goLogin() {
        var key = 'productDetailGoLogin';
        var val = $.cookie(key) || 0;
        $.cookie('productDetailGoLogin', val | 0x1, { path: '/' });
        window.location.href = '/login.jsp?url=' + Fai.encodeUrl(window.location.pathname + window.location.search);
    }

    // 评分
    setCommentStar(dom) {
        var $starDom = this.$el.find('.evaluate_star'),
            selectClass = 'select_star jz_theme_font_color',
            addedStarText = LS.score + LS.js_highComment;

        $starDom.find('i').addClass(selectClass);
        dom.find('i').removeClass(selectClass);
        this.$el.find('.evaluate_star_text').text($starDom.find('.select_star').length + addedStarText);
    }

    showProductQRCode(url) {
        var qrCodeHtml = '';

        qrCodeHtml += "<div class='detail_QR_code'>";
        qrCodeHtml += "<img title='' src='" + url + "' >";
        //qrCodeHtml += 	"<span>"+ LS.productORCodeMsg +"</span>";
        qrCodeHtml += '</div>';

        $(qrCodeHtml).appendTo('.module_product_detail_content');
    }

    selectSmallPic($target) {
        var index = $target.attr('index');
        this.$el
            .find('.detail_big_pic' + index)
            .show()
            .siblings()
            .hide();
        $target
            .addClass('select_small_pic jz_theme_border_color')
            .siblings()
            .removeClass('select_small_pic jz_theme_border_color');
    }

    arrowSmallPic(direction) {
        if (this.conf.prop0 != 2) {
            var $list = this.$el.find('.detail_small_pic_lists'),
                $selectPic = $list.find('.select_small_pic'),
                listWidth = $list.width(),
                index = parseInt($selectPic.attr('index') || 0),
                $toPic,
                smallPicLeft,
                smallPicWidth;

            if (direction == 'prev') {
                index > 0 && --index;
            } else if (direction == 'next') {
                index < $list.find('li').length - 1 && index++;
            }

            this.selectSmallPic($list.find('li').eq(index));

            $toPic = $list.find('li').eq(index);
            smallPicLeft = $toPic.position().left;
            smallPicWidth = $toPic.outerWidth(true);

            if (listWidth < smallPicLeft) {
                // 小图超出右边可视区域
                $list.animate(
                    {
                        scrollLeft: smallPicLeft,
                    },
                    500
                );
            } else if (listWidth < smallPicLeft + smallPicWidth) {
                // 小图在部分被挡住
                $list.animate(
                    {
                        scrollLeft: $list.scrollLeft() + smallPicWidth,
                    },
                    500
                );
            } else if (smallPicLeft < 0) {
                // 小图在左边区域并且不再可视区
                $list.animate(
                    {
                        scrollLeft: smallPicLeft,
                    },
                    500
                );
            }

            var bf = $('.detail_inner_wrap  .bf');
            if (index == 0 && bf.length == 2) {
                $(bf).show();
            }
            var video = $('.videoBg');
            if (index != 0 && video.length == 1) {
                $(video).remove();
            }
        }
    }
};
