
import PaginationComponent from '@/components/modules/common/pagination/index.vue';
import { mapGetters, mapState } from 'vuex';
import { memberGetOrderList } from '@/api/product/index';
import { PayProduct } from '@/services';
import { dayjs } from '@jz/utils';
import { calcRemain } from '@/components/modules/placeOrder/composable/useCountDown.js';
import { Comm } from '../../utils';

export default {
    components: {
        PaginationComponent,
    },
    props: {
        module: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            currentPage: 1,
            pageSize: 10,
            total: 0,
            list: [],
        };
    },
    methods: {
        handlePaginationPageChange(page) {
            this.currentPage = page;
            this.fetchList();
        },
        fetchList() {
            if (this.$isServer) {
                return;
            }
            memberGetOrderList({
                pageNo: this.currentPage,
                pageSize: this.pageSize,
            }).then((res) => {
                if (res.success) {
                    const list = res.orderList;
                    list.forEach((o) => {
                        if (o.status === PayProduct.payProductStatus.WAIT_PAY) {
                            o.remainDays = 0;
                            o.remainHours = 0;
                            o.remainMinutes = 0;
                            o.remainSeconds = 0;
                        }
                    });
                    this.list = list;
                    this.total = res.totalSize;
                }
            });
        },
        initCountDown() {
            const countDown = () => {
                this.list.forEach((o) => {
                    if (
                        o.status !== PayProduct.payProductStatus.WAIT_PAY ||
                        typeof o.remainCancelTime === 'undefined'
                    ) {
                        return;
                    }
                    if (o.remainCancelTime <= 0) {
                        o.status = PayProduct.payProductStatus.CLOSED;
                        return;
                    }
                    const { remainDays, remainHours, remainMinutes, remainSeconds } = calcRemain(o.remainCancelTime);
                    o.remainDays = remainDays;
                    o.remainHours = remainHours;
                    o.remainMinutes = remainMinutes;
                    o.remainSeconds = remainSeconds;
                    o.remainCancelTime -= 1000;
                });
            };

            countDown();

            const timer = setInterval(countDown, 1000);

            this.$once('hook:beforeDestroy', () => {
                clearInterval(timer);
            });
        },
    },
    computed: {
        ...mapGetters(['isMobi']),
        ...mapState(['LS']),
        paginationPageCount() {
            return Math.ceil(this.total / this.pageSize);
        },
    },
    created() {
        this.fetchList();
    },
    mounted() {
        this.initCountDown();
    },
    render() {
        const LS = this.LS;

        const addUnit = (price, unit) => {
            return `${unit}${price}`;
        };

        const getIdNode = (item) => (
            <div class="member_product_item_id">
                {LS.orderCode}：{item.code}
            </div>
        );
        const getStatusNode = (item) => (
            <div
                class={{
                    member_product_item_status: true,
                    wait_pay_status_text: item.status === PayProduct.payProductStatus.WAIT_PAY,
                }}
            >
                {PayProduct.getPayProductStatus(item.status, LS)}
            </div>
        );
        const getImageNode = (item) => (
            <div class="member_product_item_img">
                <img src={item.pdImgPath} alt={item.pdName} onClick={() => window.open(item.jumpUrl)} />
            </div>
        );
        const getNameNode = (item) => (
            <div class="member_product_item_name" onClick={() => window.open(item.jumpUrl)}>
                {item.pdName}
            </div>
        );
        const getPriceAndCountNode = (item, unit) => (
            <div class="member_product_item_price">
                <div class="member_product_item_price_unit">{addUnit(item.priceStr, unit)}</div>
                <div class="member_product_item_price_count">x{item.amount}</div>
            </div>
        );
        const getTotalNode = (item, unit) => (
            <div class="member_product_item_total">{addUnit(PayProduct.getTotalPrice(item), unit)}</div>
        );
        const getOperationNode = (item) => {
            const viewOrder = () => {
                window.open(item.orderDetailUrl);
            };
            const cancelOrder = () => {
                PayProduct.cancelOrder(item.id).then(() => {
                    this.fetchList();
                });
            };
            const confirmReceipt = () => {
                PayProduct.confirmReceipt(item.id).then(() => {
                    this.fetchList();
                });
            };
            const operation = {
                [PayProduct.payProductStatus.WAIT_PAY]: [
                    {
                        text: LS.checkOrders,
                        onClick: viewOrder,
                    },
                    {
                        text: LS.orderRealCancel,
                        onClick: cancelOrder,
                    },
                ],
                [PayProduct.payProductStatus.SENT]: [
                    {
                        text: LS.checkOrders,
                        onClick: viewOrder,
                    },
                    {
                        text: LS.orderSureRpt,
                        onClick: confirmReceipt,
                    },
                ],
            }[item.status] || [
                {
                    text: LS.checkOrders,
                    onClick: viewOrder,
                },
            ];
            return (
                <div class="member_product_item_operation">
                    {operation.map((item) => (
                        <div class="member_product_item_operation_btn" onClick={item.onClick}>
                            {item.text}
                        </div>
                    ))}
                </div>
            );
        };

        const getItemNodeInMobi = (item) => {
            const payText =
                PayProduct.payProductStatus.WAIT_PAY === item.status ? LS.shouldPayPrice : LS.actualPayPrice;
            const isStatusClosed = item.status === PayProduct.payProductStatus.CLOSED;
            return (
                <div class="member_product_item_mobi">
                    <div class="member_product_item_header_mobi">
                        {getIdNode(item)}
                        {getStatusNode(item)}
                    </div>
                    {item.itemList.map((productItem) => {
                        return (
                            <div class="member_product_item_info_wrap">
                                <div class="member_product_item_info_left_mobi">{getImageNode(productItem)}</div>

                                <div class="member_product_item_info_right_mobi">
                                    {getNameNode(productItem)}
                                    {getPriceAndCountNode(productItem, item.currencySymbol)}
                                </div>
                            </div>
                        );
                    })}
                    {!isStatusClosed && (
                        <div class="member_product_item_total_wrap_mobi">
                            <div class="member_product_item_amount_mobi">{payText}: </div>
                            <div class="member_product_item_total">
                                {addUnit(Number(item.price).toFixed(2), item.currencySymbol)}
                            </div>
                        </div>
                    )}
                    {getOperationNode(item)}
                </div>
            );
        };

        const getItemNodeInPc = (item) => {
            const countDownStr = Comm.format(
                LS.pay_limit_tips5,
                item.remainDays,
                item.remainHours,
                item.remainMinutes,
                item.remainSeconds
            );
            const isShowCountDown =
                item.status === PayProduct.payProductStatus.WAIT_PAY && !this.module.data.closeAutoCancelOrder;
            return (
                <div class="member_product_item">
                    <div class="member_product_item_header">
                        <div class="member_product_item_header_left">
                            {getIdNode(item)}
                            <div class="member_product_item_time">
                                {LS.orderListCreateTime}: {dayjs(item.createTime).format('YYYY-MM-DD HH:mm:ss')}
                            </div>
                        </div>
                        {isShowCountDown && (
                            <div class="member_product_item_header_right">
                                <div class="member_product_item_count_down">{countDownStr}</div>
                            </div>
                        )}
                    </div>
                    {item.itemList.map((productItem, index) => {
                        return (
                            <div class="member_product_item_content">
                                <div class="member_product_item_info">
                                    {getImageNode(productItem)}
                                    {getNameNode(productItem)}
                                </div>
                                {getPriceAndCountNode(productItem, item.currencySymbol)}
                                {getTotalNode(productItem, item.currencySymbol)}
                                {index == 0 ? getStatusNode(item) : <div class="member_product_item_status"></div>}
                                {index == 0 ? (
                                    getOperationNode(item)
                                ) : (
                                    <div class="member_product_item_operation"></div>
                                )}
                            </div>
                        );
                    })}
                </div>
            );
        };
        return (
            <div class="member_bought_wrap member_product_list_wrap">
                {!this.isMobi && (
                    <div class="member_product_list_header">
                        <div class="member_product_header_name">{LS.orderListItemPdName}</div>
                        <div class="member_product_header_price">
                            {LS.orderListItemPdPrice} x {LS.amount}
                        </div>
                        <div class="member_product_header_total">{LS.orderListItemPrice}</div>
                        <div class="member_product_header_status">{LS.memberformStatus}</div>
                        <div class="member_product_header_operation">{LS.orderListItemOpt}</div>
                    </div>
                )}
                <div class="member_product_list">
                    {this.list.map((item) => {
                        return this.isMobi ? getItemNodeInMobi(item) : getItemNodeInPc(item);
                    })}
                </div>
                <PaginationComponent
                    key={this.paginationPageCount}
                    moduleId={8}
                    pageCount={this.paginationPageCount}
                    currentPage={this.currentPage}
                    pageSize={this.pageSize}
                    styleIndex={1}
                    onCurrentChange={this.handlePaginationPageChange}
                />
            </div>
        );
    },
};
