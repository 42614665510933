
import { mapState } from 'vuex';

import { openSiteForm } from '@shared/visitor/form/index.js';
import { bindUeSlider, ueHoverStyle } from '@/components/modules/shared/ue/index.js';
import { Comm, getBitMemory } from '../../utils';
const { bindFlag } = Comm;
import { triggerServiceNumber } from '@/components/modules/shared/triggerServiceNumber/index.js';
import { logDog, logJZFdp } from '@/site/shared/log/index.js';

import { successMessage, warningMessage } from '@/site/shared/dialog/index.js';
import { setHrefEventHasReqArgs } from '@/site/shared/cookie/index.js';
import { ueHoverChangeImage } from '@shared/visitor/rich/index.js';
import { getProductVideo } from './productDetail.js';
import { productDetail } from '@shared/visitor/productDetail/index.js';
import { TableResponsive, Clue } from '@jz/biz-shared';
import { deepExtendClone } from '@jz/utils';
import { slidesshowDataMixin } from '../moduleSlidesshowMixin';
import { submitComment } from '@api/product';
import { encodeHtmlStr } from '../../utils';
import ImageMainViewer from '@/components/modules/common/imageMainViewer/index.vue';
import CommentFiveStar from '../common/commentFiveStar.vue';
import JzShareSsrCompon from '@/components/modules/common/shareCompon/index.vue';
import productComment from '@/components/modules/common/productComment/index.vue';
import PrevNext from './components/PrevNext.vue';

import HtmlRenderer from '@/components/htmlRenderer/index.vue';

import { MobileAuthPopupService, MOBILE_AUTH_MESSAGE_TYPES, ServiceFactory, PayKnowProduct } from '@/services/index';
import { topTips, realNameAuthTips } from '@/site/topTips';
import PayMixin from './mixins/pay';
import HlsVideo from './HlsVideo.vue';
import AudioController from './AudioController/index.vue';
import ColumnContent from './ColumnContent/index.vue';
import PayProduct from './PayProduct/index.vue';
import { PayProduct as PayProductService } from '@/services';


import { createLoginPopup } from '@/site/shared/loginPopup/index';
import { fileDownloadLimitHandler } from '@/shared/visitor/fileDownload';

export default {
    name: 'ProductDetail',
    style: 9,
    components: {
        ImageMainViewer,
        CommentFiveStar,
        JzShareSsrCompon,
        productComment,
        HtmlRenderer,
        HlsVideo,
        AudioController,
        ColumnContent,
        PayProduct,
        PrevNext,
    },
    provide() {
        return {
            productDetailVm: this,
        };
    },
    mixins: [PayMixin, slidesshowDataMixin],
    props: ['id', 'module'],
    data() {
        if (!this.module.productInfo) {
            return {
                productInfo: {},
                advisoryInfo: {},
            };
        }

        const productInfo = this.module.productInfo,
            choiceCurrencyVal = productInfo.choiceCurrencyVal,
            firstPicPath = productInfo.picPath,
            picPathList = productInfo.picPathList,
            productTitle = productInfo.name,
            avgScore = productInfo.avgScore,
            isOpen = productInfo.isOpen,
            moduleId = this.module.id,
            col_product_detail = this.module.col_product_detail,
            share = this.module.share,
            qrcode = this.module.qrcode,
            productCommentCreator = this.module.productCommentCreator,
            uploaded = this.module.uploaded,
            picFormat = this.module.picFormat,
            pd_praiseDegree = this.module.pd_praiseDegree,
            msgBoardContent = this.module.msgBoardContent,
            verificationCode = this.module.verificationCode,
            productId = productInfo.id,
            showTabLists = this.module.prop5.info,
            advisoryInfo = this.module.advisoryInfo || {},
            openCommStar = this.module.openCommStar;
        const { writeComment } = this.module;
        let tabItem,
            tabItemFlag = false;

        for (let index = 0; index < showTabLists.length; index++) {
            const val = showTabLists[index];

            if (val.id !== 100) {
                if (val.open && !tabItemFlag) {
                    tabItem = val.id;
                    tabItemFlag = true;
                }
            }
        }

        const data = {
            productInfo,
            choiceCurrencyVal,
            firstPicPath,
            picPathList,
            productTitle,
            avgScore,
            isOpen,
            moduleId,
            col_product_detail,
            share,
            qrcode,
            productCommentCreator,
            uploaded,
            picFormat,
            pd_praiseDegree,
            msgBoardContent,
            verificationCode,
            productId,
            showTabLists,
            controller: {},
            totalSize: 0,
            msgBoradChageValidateCode: this.module.msgBoradChageValidateCode,
            SYS_MALL_PRICE: 11,
            // 商城价（实际交易价格
            SYS_MALL_MARKET_PRICE: 12,
            // 市场价
            SYS_SALES: 17,
            // 销量
            tabItem: tabItem || 1,
            commentLoc: {
                writeComment,
            },
            activeIndex: 0,
            rightContentStyle: {
                top: 'auto',
                left: 'auto',
                width: 'auto',
                position: 'static',
            },
            imgRadioStyle: {
                top: 'auto',
                left: 'auto',
                position: 'static',
            },
            topIndex: 0,
            advisoryInfo,

            openCommStar, // 是否开启星评

            shareToService: null,
        };
        return data;
    },
    computed: {
        ...mapState([
            'device',
            'manageMode',
            'jzVersion',
            'LS',
            'responsiveInfo',
            'realNameAuth',
            'resImageRoot',
            'sections',
        ]),
        isPC() {
            return this.device === 'pc';
        },
        isPayProduct() {
            return PayProductService.isPayProduct(
                this.productInfo,
                this.module.commerceSettings?.openECommerce ?? false
            );
        },
        currencyPositionRight() {
            return this.siteCurrencyRight && !this.isRMB;
        },
        sales() {
            return this.productInfo[this.getSpecialProp(this.SYS_SALES).fieldKey];
        },
        logEventConfig() {
            return {
                event: 'jz_hdpzj',
                properties: {
                    jz_content_terminal: '自适应-产品展示-产品详情',
                    jz_version: this.jzVersion,
                },
            };
        },

        previewSrcList() {
            if (this.isPayKnowProduct) {
                return [{ src: this.knowProductModel.productImgUrl }];
            }
            const curList = this.productInfo.picPathList || [],
                _previewSrcList = [];

            if (curList.length) {
                curList.forEach((value) => {
                    let _preview = {};
                    _preview.src = value.path;

                    _previewSrcList.push(_preview);
                });
            } else {
                let _preview = {};
                _preview.src = this.productInfo.picPath;

                _previewSrcList.push(_preview);
            }

            return _previewSrcList;
        },

        commentPlaceholder() {
            if (this.module.productCommentList.length > 0) {
                return this.module.comeToComment;
            }

            return this.module.hasNoComment;
        },

        hasProps() {
            const { propList, isShowMessageSales, SYS_MALL_PRICE, SYS_MALL_MARKET_PRICE, productInfo, showList } = this;
            const filterList = propList.filter((prop) => {
                return (
                    isShowMessageSales(prop.id) &&
                    prop.type != SYS_MALL_PRICE &&
                    prop.type != SYS_MALL_MARKET_PRICE &&
                    showList.indexOf(prop.id) != -1 &&
                    !!productInfo[prop.fieldKey]
                );
            });
            return !!filterList.length;
        },

        moduleClasses() {
            const classes = {},
                style = this.module.prop0;
            classes['jz_product_detail_' + style] = true;

            if (this.isAudioPayKnowProduct) {
                classes['product_detail_audio_pay_know'] = true;
            }

            return classes;
        },

        emptyProduct() {
            return this.module?.emptyProduct || Object.keys(this.productInfo).length == 0;
        },

        markDegree() {
            return parseInt((this.avgScore || 5) * 20);
        },

        scaleBlockStyle() {
            return {
                left: this.markDegree + '%',
            };
        },

        smallPicBeyondArea() {
            // 5 是展示容纳量
            return this.picPathList && this.picPathList.length > 5;
        },

        curTotalSize() {
            if (this.totalSize.toString().length > 3) {
                // 超过999条评论，显示999+
                return this.totalSize + '+';
            }

            return this.totalSize;
        },

        bigBg() {
            return 'url(' + this.productInfo.picPath + ') no-repeat center';
        },

        showVideo() {
            return this.productInfo.productVideo != '' && this.productInfo.productVideo != undefined ? true : false;
        },

        normalProductDetailContent() {
            if (!this.isPC && typeof this.productInfo.mobiRemark === 'string') {
                return this.productInfo.mobiRemark;
            }
            return this.productInfo.encodeRemark;
        },

        productDetailContent() {
            if (this.isPayKnowProduct) {
                return this.payKnowProductHtml;
            }
            return this.normalProductDetailContent;
        },

        payKnowProductHtml() {
            if (this.isImageTextPayKnowProduct) {
                if (this.payKnowBought) {
                    return this.normalProductDetailContent;
                }
                const shouldPayImageHtml = PayKnowProduct.getPayTipsContentHtml(this.resImageRoot);
                let trialRemark = '';
                if (this.isMobi && typeof this.knowProductModel.mobiTrialRemark === 'string') {
                    trialRemark = this.knowProductModel.mobiTrialRemark;
                } else {
                    trialRemark = this.knowProductModel.trialRemark;
                }
                return trialRemark + shouldPayImageHtml;
            }
            return this.normalProductDetailContent;
        },

        showTabLen() {
            var len = 0;
            this.showTabLists.forEach((val) => {
                if (val.open) {
                    len++;
                }
            });
            return len;
        },

        isNonePicStyle() {
            return this.module.prop0 == 1 ? false : true;
        },

        isScrollStyle() {
            return this.module.prop0 == 2 ? false : true;
        },

        isRightContentStyle() {
            if (this.module.prop0 == 2) {
                return this.rightContentStyle;
            } else {
                return {};
            }
        },

        isImgRadioStyle() {
            if (this.module.prop0 == 2) {
                return this.imgRadioStyle;
            } else {
                return {};
            }
        },

        showDefaultImg() {
            let picPathList = this.productInfo.picPathList || [];
            if (
                (this.module.prop0 == 2 && picPathList.length == 0) ||
                (this.module.prop0 == 0 && this.device != 'mobi') ||
                (this.module.prop0 == 0 && this.device == 'mobi' && picPathList.length == 0) ||
                (this.showVideo && this.device != 'mobi')
            ) {
                return true;
            } else {
                return false;
            }
        },

        qrFlag() {
            if (this.productInfo.ptQRCodeOpen) {
                return true;
            } else {
                return false;
            }
        },

        isShowSales() {
            if (this.module.prop0 == 1 && !this.isPayProduct) {
                return false;
            }

            let money = this.propList.filter((item) => {
                return item.id == 11;
            });
            let sales = this.propList.filter((item) => {
                return item.id == 17;
            });

            if (money.length == 0 || sales == 0) {
                return false;
            }

            let result = this.showList.some((item) => {
                return item == money[0].id;
            });

            if (result) {
                result = this.showList.some((item) => {
                    return item == sales[0].id;
                });
            }

            return result;
        },

        mobiNoneMsgStyle() {
            let result = null;
            let money = this.propList.filter((item) => {
                return item.id == 11;
            });
            let sales = this.propList.filter((item) => {
                return item.id == 17;
            });

            if (money.length == 0 || sales == 0) {
                return false;
            }

            let hasMoney = this.showList.some((item) => {
                return item == money[0].id;
            });

            if (hasMoney) {
                result = this.showList.filter((item) => {
                    return item != sales[0].id && item != money[0].id;
                });
            } else {
                result = this.showList;
            }

            if (result.length == 0) {
                return {
                    padding: '16px 0px 0px 0px',
                };
            } else {
                return {};
            }
        },

        flagQrCode: bindFlag(0x1),
        flagShare: bindFlag(0x2),
        flagPrevNext: bindFlag(0x4),
        mobileView: {
            set(val) {
                return val;
            },

            get() {
                if (this.manageMode) {
                    return this.device === 'mobi' || $('.jz_screen_mobi').length > 0;
                } else {
                    return false;
                }
            },
        },

        showParameter() {
            return this.device == 'mobi' ? true : false;
        },

        needHover() {
            let flag = this.module.prop0 == 2;
            let hoverStyle = {};

            if (flag) {
                hoverStyle = {
                    'border-color': '#EBEBEB',
                };
            }

            return hoverStyle;
        },

        svgGap() {
            if (this.module.prop0 == 1) {
                return '28px';
            } else if (this.module.prop0 == 2) {
                return '20px';
            } else {
                return '40px';
            }
        },

        emptyDetailMsg() {
            return this.manageMode ? '请输入产品详情内容' : '';
        },

        isMobi() {
            return this.device == 'mobi';
        },

        propList: {
            get() {
                if (!this.module.productInfo) {
                    return [];
                }

                return this.module.finalShowPropListByLib['lib_' + this.module.productInfo.lid] || [];
            },

            set(val) {
                this.module.finalShowPropListByLib['lib_' + this.module.productInfo.lid] = val;
            },
        },

        showList() {
            if (!this.module.productInfo) {
                return [];
            }

            return this.module.prop1.s['lib_' + this.module.productInfo.lid] || [];
        },

        isShowPrice() {
            let price = this.propList.find((p) => {
                return p.id == 11;
            });
            let flag = this.showList.some((id) => {
                return id == 11;
            });
            return flag && price?.allowed;
        },

        isShowMarketPrice() {
            let marketPrice = this.propList.find((p) => {
                return p.id == 12;
            });
            let flag = this.showList.some((id) => {
                return id == 12;
            });
            return flag && marketPrice?.allowed;
        },

        formatPrice() {
            if (this.productInfo.actualPrice) {
                return this.productInfo.actualPrice;
            } else {
                return /\./.test(`${this.productInfo.mallPrice}`)
                    ? this.productInfo.mallPrice.toFixed(2)
                    : this.productInfo.mallPrice.toFixed(2);
            }
        },

        formatMarketPrice() {
            return /\./.test(`${this.productInfo.mallMarketPrice}`)
                ? this.productInfo.mallMarketPrice.toFixed(2)
                : this.productInfo.mallMarketPrice.toFixed(2);
        },

        downloadModel() {
            return this.module.downloadModel || {};
        },

        downloadAuth() {
            return this.module.currentPdAttachDownloadAuthForMember ?? {};
        },

        downloadUrl() {
            if (this.manageMode === true) {
                return this.downloadModel.downloadUrl;
            }
            const notAllow =
                this.downloadAuth.isNeedLogin === true || this.downloadAuth.currentMemberPdAttachAllow === false;
            return notAllow ? 'javascript:;' : this.downloadModel.downloadUrl;
        },

        showDownload() {
            return this.downloadModel.allow && this.downloadModel.hasFile && this.downloadModel.open;
        },

        siteCurrencyRight() {
            return this.productInfo.siteCurrencyRight || false;
        },

        isRMB() {
            return this.choiceCurrencyVal == '￥';
        },

        memberName() {
            return this.sections.memberName || '';
        },
    },
    watch: {
        'module.pattern.pc': {
            deep: true,

            handler() {
                if (this.module.prop0 == 2) {
                    this.bindScroll();
                    this.notAnmiateStyle();
                }
            },
        },
        'productInfo.picPathList': {
            deep: true,

            handler() {
                if (this.productInfo.picPathList.length <= 1) {
                    this.$nextTick(() => {
                        this.notAnmiateStyle();
                    });
                } else {
                    this.$nextTick(() => {
                        this.initPosition();
                        this.imgScroll();
                        this.notAnmiateStyle();
                    });
                }
            },
        },
        productInfo: {
            deep: true,

            handler(val) {
                this.propList = val.propList;
            },
        },
        showTabLists: {
            deep: true,

            handler(lists) {
                // 选中项保护逻辑
                let that = this,
                    canOpenList = [],
                    selectItemClose = false;
                lists.forEach((val) => {
                    val.open && canOpenList.push(val.id);

                    if (val.id == that.tabItem) {
                        if (val.open) {
                            return false;
                        } else {
                            selectItemClose = true;
                        }
                    }
                });
                selectItemClose && !!canOpenList.length && (this.tabItem = canOpenList.shift());
            },
        },
        showList: {
            deep: true,

            handler() {
                if (!this.isNonePicStyle) {
                    this.hideLastBorderLine();
                }
            },
        },

        device(val) {
            if (val == 'mobi') {
                document.removeEventListener('scroll', this.bindScroll);
                $('.product_detail_right_content').addClass('product_detail_right_content_mobi');
                $('.product_detail_right_content').removeClass('product_detail_right_content_noImage');
                this.calculateFixed();
                this.mobiBindEvent();
            }
        },

        'module.prop0': {
            handler(val) {
                this.sitelog();

                if (val == 2) {
                    this.$nextTick(() => {
                        window.addEventListener('resize', this.bindResize, false); // 初始化 图片入口 radio 和 产品描述区域 的位置

                        this.initPosition(); // 给document绑定 scroll,resize事件

                        this.imgScroll();
                        this.notAnmiateStyle();
                    });
                } else if (val == 1 || val == 0) {
                    this.hideLastBorderLine();
                    $('.product_detail_right_content').removeClass('product_detail_right_content_noImage');
                    document.removeEventListener('scroll', this.bindScroll);
                    window.removeEventListener('resize', this.bindResize);
                }
            },
        },
        encodeRemark: function () {
            this.ueHoverImageFunc();
            this.$nextTick(() => {
                this.handleTableResponsive();
            });
        },
    },
    created() {},

    mounted() {
        this.initDeviceWatch();
        this.initShareVM();
        this.controller = new productDetail(this.id, this.module);

        if (!this.isNonePicStyle) {
            this.hideLastBorderLine();
        }

        this.mobileView = this.device === 'mobi' || $('.jz_screen_mobi').length > 0;
        this.sitelog(); // pc端吸顶绑定事件

        window.addEventListener('resize', this.bindResize, false);

        if (!this.isScrollStyle && this.device == 'pc') {
            // 初始化 图片入口 radio 和 产品描述区域 的位置
            this.initPosition(); // 给document绑定 scroll

            this.imgScroll(); // 判断左侧图片高度是否大于右侧产品描述高度来决定是否需要动画

            this.notAnmiateStyle();
        } else {
            $('.product_detail_right_content').addClass('product_detail_right_content_mobi');
        } // 自定义样式3后，手机端吸顶绑定事件

        if (this.device == 'mobi') {
            this.mobiBindEvent();
        }

        this.calculateFixed();
        this.ueHoverImageFunc(); // 修复上一页下一页的错误

        this.initProductAdvisory(); // 修复产品按钮mobi视图会挡住版权信息

        this.fixProductButton();

        if (this.showVideo) {
            let videoDomSelector;
            if (this.isPayProduct) {
                videoDomSelector = '.pay_product_image--video';
            }
            getProductVideo(0, this.productInfo.id, videoDomSelector); // 有主图则去请求视频
        }

        setHrefEventHasReqArgs($(`#module${this.module.id}`));
        this.handleTableResponsive(); // 浏览产品详情页行为上报

        !this.manageMode && Clue?.logClueProductDetailSourceContent();

        this.initRealNameAuthTips();

    },

    beforeDestroy() {
        document.removeEventListener('scroll', this.bindScroll);
        window.removeEventListener('resize', this.bindResize, false);
        this.removeMobiEvent();
    },
    methods: {
        fixProductButton() {
            if (this.isPayProduct) {
                return;
            }

            const showProductButton =
                this.advisoryInfo.openProductAdvisory || this.productInfo.openProductForm || this.isPayKnowProduct;
            $('#gridFooter').removeClass('mobi_product_btn_fix');
            $('.jz_web_content').removeClass('mobi_product_btn_fix');

            if (this.isMobi) {
                if (showProductButton) {
                    if ($('#gridFooter').length > 0) {
                        $('#gridFooter').addClass('mobi_product_btn_fix');
                    } else {
                        $('.jz_web_content').addClass('mobi_product_btn_fix');
                    }
                }
            }
        },
        tabClick(tabList) {
            let classNameList = this.tabContentClass(tabList);
            window.bizShared.renderSingleMap(`.${classNameList.length > 1 ? classNameList[1] : classNameList[0]}`);
        },
        encodeHtmlStr(str = '') {
            return encodeHtmlStr(str).replace(/&nbsp;/g, ' ');
        },

        initDeviceWatch() {
            // 因为 手机切换为 pc的时候会有个动画 transition: all .3s; ，影响initPosition的定位
            this.$device.on(this.$device.EVENTS.ON_CHANGE_AFTER_TRANSITION, (val) => {
                if (val == 'pc') {
                    $('.product_detail_right_content').removeClass('product_detail_right_content_mobi');
                    $('.product_detail_right_content').removeClass('product_detail_right_content_noImage');
                    this.initPosition();
                    this.imgScroll();
                    this.notAnmiateStyle();
                    this.removeMobiEvent();
                }
                this.fixProductButton();
            });
        },

        initShareVM() {
            $('#jzWebContainer').append('<div id="productShare"><div>');
            const app = this;

            let shares = this.module.shareParamList.filter((item) => item.linkIsShow).map((item) => item.name);
            const openShare = getBitMemory(this.module.flag, 0x2) === false;
            if (openShare === false) {
                shares = [];
            }

            if (this.manageMode === false) {
                logJZFdp('jz_product_pages_visit', {
                    jz_content_terminal: '自适应',
                    jz_free_int1: this.module.prop0,
                    jz_free_text1: JSON.stringify(shares),
                });
            }

            new Vue({
                el: '#productShare',
                components: {
                    JzShareSsrCompon,
                },
                data: {
                    list: deepExtendClone(this.module.prop3),
                    shareParamList: deepExtendClone(this.module.shareParamList),
                },
                methods: {
                    shareToPinterest: app.shareToPinterest,
                },
                store: this.$store,
                template: `<div id="productShare${this.module.id}" class="jz_share_info">
                                <jz-share-ssr-compon
                                    :list="list"
                                    :share-param-list="shareParamList"
                                    @share-to-pinterest="shareToPinterest"
                                    source='产品详情'
                                >
                                </jz-share-ssr-compon>
                            </div>`,
            });
        },

        bindUeSlider(e, type) {
            if (e.target.src?.includes('image/v2/pay.png')) {
                // 提示图不用出大图显示
                return;
            }
            if (this.isMobi && type === 'productDetail' && this.knowProductContentDisableCopy) {
                return;
            }
            bindUeSlider(e, '自适应-产品详情-ue', {
                disableContextMenu: this.knowProductContentDisableCopy,
            });
        },

        photoSlide(index) {
            let isPhotoSlides = this.isPhotoSlides;
            // 不知道为什么有的账号为啥这个数据为2，打开编辑面板那里会修复成1
            if (this.photoSlides.o == 2) {
                isPhotoSlides = true;
            }
            if (isPhotoSlides) {
                if (!this.productInfo.picPathList[index]) {
                    return;
                }
                this.imageIndex = index;

                if (this.isMobi) {
                    this.showMobiViewer = true;
                } else {
                    this.showPcViewer = true;
                }
            }
        },

        handleTableResponsive() {
            TableResponsive.init({
                $tables: $('.module_content_detail table'),
                parentClassName: '.module_content_detail',
            });
        },

        openForm() {
            const { openProductForm, productFormId, id } = this.productInfo;
            if (!openProductForm || !productFormId || productFormId < 0) return;
            openSiteForm?.(productFormId, id, true);
        },

        initProductAdvisory() {
            if (!this.advisoryInfo) return;
            const { computerAdvisoryJumpInfo, advisoryWay } = this.advisoryInfo;
            const CUSTOM_LINK_TYPE = 3;
            if (
                computerAdvisoryJumpInfo.t == 114 &&
                computerAdvisoryJumpInfo.u.includes('_script:') &&
                advisoryWay === CUSTOM_LINK_TYPE
            ) {
                $(this.$refs.productAdvisoryBtn)
                    .off('mouseenter')
                    .on('mouseenter', function () {
                        // 临时加eval
                        eval(computerAdvisoryJumpInfo.u.replace('_script:', ''));
                    });
            }
        },

        handleAdvisoryClick() {
            const { isMobi } = this;
            const { advisoryWay, wechatImgPath, computerAdvisoryJumpInfo, advisoryPhone } = this.advisoryInfo;

            function preventDefault(e) {
                e.preventDefault();
            }

            if (advisoryWay == 0) {
                if (isMobi) triggerServiceNumber(1, advisoryPhone);
            } else if (advisoryWay == 1) {
                const htmlContent = `
                        <style>
                            .product-advisory { box-shadow: none; }
                            ${
                                isMobi
                                    ? '.product-advisory .popupBClose { top: 7px; right: 11px;}'
                                    : '.product-advisory .popupBClose { top: 25px; right: 32px;}'
                            }
                        </style>
                        <div style="padding-top: ${isMobi ? 18 : 45}px;">
                            <img style="display: block; margin: 0 auto; width: ${isMobi ? 210 : 265}px; height: ${
                    isMobi ? 210 : 265
                }px !important;" src="${wechatImgPath}" />
                            <p style="text-align: center; margin-top: 30px; font-size: ${
                                isMobi ? 16 : 18
                            }px; color: #333; margin-bottom:10px;">${this.LS.scanWeChatCodeTip}</p>
                        </div>
                    `;
                document.addEventListener('touchmove', preventDefault, {
                    passive: false,
                });
                $('html, body').css('overflow', 'hidden');
                Site.popupBox({
                    htmlContent,
                    width: isMobi ? 280 : 440,
                    height: isMobi ? 310 : 450,
                    extClass: 'product-advisory',
                    popupBoxZIndex: 9999,

                    closeFunc() {
                        document.removeEventListener('touchmove', preventDefault, {
                            passive: false,
                        });
                        $('html, body').css('overflow', '');
                    },
                });
            } else if (advisoryWay == 3) {
                const { ot, u, t } = computerAdvisoryJumpInfo;
                if (t == 114 && u.includes('_script:')) return;
                $(`<a style="display: none;" id="advisoryTempHref" href='${u}' target="${ot}"></a>`).appendTo(
                    $('body')
                );
                $('#advisoryTempHref')[0].click();
                $('#advisoryTempHref').remove();
            }

            logDog(201121, 6);
        },

        getSpecialProp(type) {
            var list = {};
            this.propList.forEach((obj) => {
                if (obj.type == type) {
                    list = obj;
                    return;
                }
            });
            return list;
        },

        getProductVideo(appendDomSelector = '') {
            // 名字就不改了 原先是请求拿视频通过自动播放来实现得
            // 解决部分机型不能自动播放  通过层级来模拟播放视频
            $('.videoBg').show();
            var videojsDom = Fai.top.$('#new_video_0_html5_api')[0]; // videojsDom && videojsDom.play();

            if (videojsDom) {
                videojsDom.play();
            } else {
                // 切换到产品图片 再切回来视频会被移除 因为不是vue的节点 是js生成的 需要重新生成
                typeof JZ != 'undefined' && getProductVideo(0, this.productInfo.id, appendDomSelector);
                setTimeout(() => {
                    $('.videoBg').show();
                    videojsDom = Fai.top.$('#new_video_0_html5_api')[0];
                    videojsDom && videojsDom.play();
                }, 300);
            }

            $('.vjs-big-play-button').css('display') !== 'none' && $('.vjs-big-play-button').click();
        },

        changeTotalSize(size) {
            this.totalSize = size;
        },

        tabListItemClass(item) {
            var tClass = [];

            switch (item.id) {
                case 1:
                    // 详情
                    tClass.push('detail_tab_info');
                    break;

                case 2:
                    // 评论
                    tClass.push('detail_tab_comment');
                    break;

                default:
                    // 自定义
                    tClass.push('detail_tab_' + item.id);
            }

            if (item.id == this.tabItem) {
                tClass.push('detail_tab_active', 'jz_theme_font_color');
            }

            return tClass;
        },

        tabPanel(item) {
            var panel = 'detail_specific_';

            switch (item.id) {
                case 1:
                    // 详情
                    panel += 'desc';
                    break;

                case 2:
                    // 评论
                    panel += 'comment';
                    break;

                default:
                    // 自定义
                    panel += item.id;
            }

            return panel;
        },

        tabContentClass(item) {
            var tClass = [];
            item.id == this.tabItem && tClass.push('show_detail_content');

            switch (item.id) {
                case 1:
                    // 详情
                    break;

                case 2:
                    // 评论
                    break;

                default:
                    // 自定义
                    tClass.push('detail_specific_' + item.id);
            }

            return tClass;
        },

        tabContent(item) {
            this.ueHoverImageFunc();
            if (!this.isPC) {
                const mobiCusTabRich = this.productInfo[`mobiCusTabRich${item.id}`];
                if (typeof mobiCusTabRich === 'string') {
                    return mobiCusTabRich;
                }
            }
            return this.productInfo['cusTabRich' + item.id];
        },

        tabListItemLabel(item) {
            return item.rname + (item.id == 2 ? '(' + this.curTotalSize + ')' : '');
        },

        showTabListItem(item) {
            if (item.id === 2 && this.realNameAuth.allowShowMessage === false) {
                return false;
            }
            return item.open || (item.open && item.id == 2 && this.isOpen);
        },

        hideLastBorderLine() {
            // 无图样式时 调用该方法隐藏最后一排的下划线borderLine
            if (this.isNonePicStyle) {
                return;
            }

            this.$nextTick(() => {
                var paramsWrap = $('.jz_screen_pc .detail_other_message .detail_other');
                var params = paramsWrap.length;
                $.each(paramsWrap, function (inde, item) {
                    $(item).addClass('no_img_border');
                });

                if (params % 2 == 0) {
                    paramsWrap.eq(-1).removeClass('no_img_border');
                    paramsWrap.eq(-2).removeClass('no_img_border');
                } else if (params % 2 == 1) {
                    paramsWrap.eq(-1).removeClass('no_img_border');
                }
            });
        },

        scrollIndexImg(e) {
            let index = e.target.dataset.index;
            this.activeIndex = index;
            let start = $('.module_content_detail').offset().top;
            let imgHeight = $('.detail_small_pic_list').outerHeight(true);
            let targetPosition = start + imgHeight * index + 'px';
            $('html,body').animate(
                {
                    scrollTop: targetPosition,
                },
                500
            );
        },

        imgScroll() {
            // 滚动动画
            document.addEventListener('scroll', this.bindScroll, false);
        },

        initPosition() {
            if (this.module.prop0 == 2) {
                let productDetailWrap = $('.jz_product_detail_2 .product_detail_wrap');
                let leftContent = $('.product_detail_left_content');
                let picList = $('.detail_small_pic_list');
                let productImgRadio = $('.product_img_radio'); //初始化产品描述 和 左侧图片 radio 入口 的 定位
                //产品描述

                let rightTop = 77 + 'px';
                let rightLeft = (leftContent.outerWidth(true) / productDetailWrap.outerWidth()) * 100 + '%';
                let rightWidth =
                    Math.floor(
                        ((productDetailWrap.outerWidth() - leftContent.outerWidth(true)) /
                            productDetailWrap.outerWidth()) *
                            100
                    ) + '%';
                this.rightContentStyle = {
                    top: rightTop,
                    left: rightLeft,
                    width: rightWidth,
                    position: 'absolute',
                }; //图片radio

                let radioTop = picList.outerHeight() / 2 + parseInt(picList.css('margin-top')) / 2 + 'px';
                let radioLeft = productImgRadio.outerWidth() / 2 + 'px';
                this.imgRadioStyle = {
                    top: radioTop,
                    left: radioLeft,
                    position: 'absolute',
                };
                $('.product_img_radio').show();
            }
        },

        bindResize() {
            this.notAnmiateStyle();
            this.bindScroll();
        },

        bindScroll() {
            let productDetailWrap = $('.product_detail_wrap');

            if (productDetailWrap.length == 0) {
                return;
            }

            let picList = $('.detail_small_pic_list');
            let rightContent = $('.product_detail_right_content');
            let leftContent = $('.product_detail_left_content');
            let imgRadio = $('.product_img_radio');
            let start = productDetailWrap.offset().top;
            let run = 0;
            let imgHeight = picList.outerHeight();
            let realisticHeight = leftContent.outerHeight() - rightContent.outerHeight();
            let imgLength, headerNavFixed; // 产品详情模块的 第三种样式才可以进行下面的函数操作。

            if (this.module.prop0 == 2) {
                // 滚动条滚动的距离
                run = $(document).scrollTop();
                headerNavFixed = $('#gridHeader .header_content').hasClass('g_header_content__fixed'); // 右侧产品描述区域的定位逻辑

                let condition =
                    headerNavFixed == true
                        ? start + realisticHeight - $('#row3').outerHeight(true)
                        : start + realisticHeight;

                if (run <= start) {
                    this.initPosition();
                } else if (run > start && run < condition) {
                    let rightTop = headerNavFixed == true ? 77 + $('#row3').outerHeight(true) + 'px' : 77 + 'px';
                    let rightLeft = leftContent[0].getClientRects()[0].right + 'px';
                    //这里不清楚为啥要用父节点宽度-左边宽度来计算，理论上可以直接拿自身宽度来的
                    //let rightWidth = productDetailWrap.outerWidth() - leftContent.outerWidth(true) + 'px';
                    let rightWidth = rightContent[0] && rightContent[0].getBoundingClientRect().width + 'px';
                    this.rightContentStyle = {
                        top: rightTop,
                        left: rightLeft,
                        width: rightWidth,
                        position: 'fixed',
                    };
                } else if (run > condition) {
                    let rightTop = realisticHeight + 'px';
                    let rightLeft = (leftContent.outerWidth(true) / productDetailWrap.outerWidth()) * 100 + '%';
                    let rightWidth =
                        Math.floor(
                            ((productDetailWrap.outerWidth() - leftContent.outerWidth(true)) /
                                productDetailWrap.outerWidth()) *
                                100
                        ) + '%';
                    this.rightContentStyle = {
                        top: rightTop,
                        left: rightLeft,
                        width: rightWidth,
                        position: 'absolute',
                    };
                } //左侧 radio 图片入口的定位逻辑

                if (this.showVideo) {
                    imgLength = picList.length;
                } else {
                    imgLength = picList.length - 1;
                }

                if (run <= start) {
                    this.initPosition();
                } else if (run > start && run < start + picList.outerHeight() * imgLength + imgRadio.height()) {
                    let radioTop = 25 + '%';
                    let radioLeft = imgRadio.outerWidth() / 2 + productDetailWrap[0].getClientRects()[0].left + 'px';
                    this.imgRadioStyle = {
                        top: radioTop,
                        left: radioLeft,
                        position: 'fixed',
                    };
                } else if (run > run < start + picList.outerHeight() * imgLength + imgRadio.height()) {
                    let radioTop =
                        picList.outerHeight() * imgLength +
                        (picList.outerHeight() + parseInt(picList.css('margin-top'))) / 2 +
                        'px';
                    let radioLeft = imgRadio.outerWidth() / 2 + 'px';
                    this.imgRadioStyle = {
                        top: radioTop,
                        left: radioLeft,
                        position: 'absolute',
                    };
                } // 滚动改变 左侧图片入口的 样式

                if (run - start > 0) {
                    let index = Math.floor((run - start) / imgHeight);
                    index = index >= this.productInfo.picPath.length ? this.productInfo.picPath.length - 1 : index;
                    this.activeIndex = index;
                } else {
                    this.activeIndex = 0;
                }
            }
        },

        mobiBindScroll() {
            let run = null;

            if (this.manageMode === true) {
                run = $('#jzPreviewContent').scrollTop();
            } else if (this.manageMode === false) {
                run = $(document).scrollTop();
            }

            this.calculateFixed();

            if (run >= this.fixedPosition) {
                $('.jz_mobi_header ').css('opacity', '0');
            } else {
                $('.jz_mobi_header').css('opacity', '1');
            }

            let contentHeight =
                $('.module_product_detail_content').scrollTop() + $('.module_product_detail_content').outerHeight();

            if (run >= this.fixedPosition && run < contentHeight) {
                $('.product_detail_specific_content').css(
                    'padding-top',
                    `${$('.detail_specific_tab_wrap').height()}px`
                );
                $('.detail_specific_tab_wrap').addClass('fixed_animation');
            } else {
                $('.product_detail_specific_content').css('padding-top', '');
                $('.detail_specific_tab_wrap').removeClass('fixed_animation');
            }
        },

        mobiBindEvent() {
            if (this.manageMode === true) {
                $('#jzPreviewContent')[0].addEventListener('scroll', this.mobiBindScroll, false);
            } else if (this.manageMode === false) {
                document.addEventListener('touchstart', this.mobiBindScroll, false);
                document.addEventListener('touchmove', this.mobiBindScroll, false);
                document.addEventListener('touchend', this.mobiBindScroll, false);
                document.addEventListener('touchcancel', this.mobiBindScroll, false);
                document.addEventListener('scroll', this.mobiBindScroll, false);
            }
        },

        removeMobiEvent() {
            if (this.manageMode === true) {
                $('.detail_specific_tab_wrap').removeClass('fixed_animation');
                $('#jzPreviewContent')[0].removeEventListener('scroll', this.mobiBindScroll);
            } else if (this.manageMode === false) {
                $('.detail_specific_tab_wrap').removeClass('fixed_animation');
                document.removeEventListener('touchstart', this.mobiBindScroll);
                document.removeEventListener('touchmove', this.mobiBindScroll);
                document.removeEventListener('touchend', this.mobiBindScroll);
                document.removeEventListener('touchcancel', this.mobiBindScroll);
                document.removeEventListener('scroll', this.mobiBindScroll);
            }
        },

        notAnmiateStyle() {
            if (this.module.prop0 == 2) {
                let rightContent = $('.product_detail_right_content');
                let differ = $('.product_detail_left_content').outerHeight() - rightContent.outerHeight() - 77;

                if (differ <= 0) {
                    // 不需要做动画了，但是如果左侧高度变化了，那么久重新加动画
                    rightContent.addClass('product_detail_right_content_noImage');
                    document.removeEventListener('scroll', this.bindScroll);
                } else {
                    rightContent.removeClass('product_detail_right_content_noImage');
                    document.addEventListener('scroll', this.bindScroll, false);
                }
            }
        },

        isShowMessageSales(id) {
            if (id == this.SYS_SALES) {
                // 如果 价格存在 那么 就出现在价格区域，this.isShowSales == true
                // 价格区域不存在 那么就 出现在参数区域  this.isShowSales == false
                if (!this.isShowSales) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        },

        async submitCommentHandler() {
            if (this.manageMode) {
                warningMessage('当前为管理状态，您不能提交评论。', true);
                return;
            }

            if (this.realNameAuth.allowSubmitMessage === false) {
                return;
            }

            const mobileAuthPopupService = new MobileAuthPopupService(this.device, this.responsiveInfo.themeColor);
            const { needAuth, showAfterReview } = await mobileAuthPopupService.needAuth(
                MOBILE_AUTH_MESSAGE_TYPES.PRODUCT_COMMENT
            );
            if (needAuth) {
                mobileAuthPopupService.createSubmitHandler(
                    () => {
                        this.submitComment(showAfterReview);
                    },
                    (error) => {
                        Fai.ing(error.msg);
                    }
                );
                mobileAuthPopupService.show();
            } else {
                this.submitComment(showAfterReview);
            }
        },

        submitComment(showAfterReview = false) {
            var _this = this;

            var productInfo = this.productInfo,
                creator = $.trim($('.evaluate_nick_name').val()),
                comment = $('.evaluate_text').val(),
                commentMinLen = productInfo.COMMENT_MINLEN,
                validateCode = $('.evaluate_auth_code').val(),
                productId = productInfo.id;

            if (typeof creator != 'string') {
                $('.commit_tip').text(this.LS.creatorTips).show();
                return;
            }

            if (typeof comment != 'string' || comment == '') {
                warningMessage(this.LS.commentNotEmpty, true);
                return;
            }

            if (comment.length < commentMinLen) {
                warningMessage(Fai.format(this.LS.commentLenTips, Fai.encodeHtml(commentMinLen)), true);
                return;
            }

            if (typeof validateCode != 'string' || validateCode == '') {
                warningMessage(this.LS.memberInputCaptcha, true);
                return;
            } // 获取上传文件的路径和文件名称

            var imgTb = $('.evaluate_upload_pic_lists'),
                commImgList = [];

            if (imgTb.length > 0) {
                var tdLength = imgTb.find('li').length;

                if (tdLength > 0) {
                    for (var i = 0; i < tdLength; i++) {
                        var tempImg = imgTb.find('li').eq(i).find('.upload_img');
                        var newImg = {},
                            imgId = tempImg.attr('_id'),
                            imgName = tempImg.attr('_name'),
                            imgSize = tempImg.attr('_file_size'),
                            tempFileId = tempImg.attr('_file_id');
                        var fileWidth = Number(tempImg.attr('_fileWidth') || 0);
                        var fileHeight = Number(tempImg.attr('_fileHeight') || 0);
                        var fileType = Number(tempImg.attr('_fileType') || 0);
                        newImg['imgId'] = imgId;
                        newImg['imgName'] = imgName;
                        newImg['imgSize'] = imgSize;
                        newImg['tempFileId'] = tempFileId;
                        newImg['imgWidth'] = fileWidth;
                        newImg['imgHeight'] = fileHeight;
                        newImg['imgType'] = fileType;
                        commImgList.push(newImg);
                    }
                }
            }

            var star = $('.evaluate_star').find('.select_star').length;

            if (!star) {
                star = 5;
            }

            var submitData = {
                validateCode: validateCode,
                productId: productId,
                creator: creator,
                commImgList: $.toJSON(commImgList),
                comment: comment,
                star: star,
            };

            submitComment(submitData)
                .then((data) => {
                    var preprocessUrl = Site.preprocessUrl({
                        path: '',
                        oldPath: '',
                    });

                    if (!data || !data.success) {
                        $('#evaluate_code_img').click();
                    }

                    Fai.removeBg();

                    switch (data.msg) {
                        case 1:
                            //验证码错误
                            warningMessage(this.LS.js_captchaError, true);
                            $('.evaluate_code_img').length > 0 && $('.evaluate_code_img').click(); // 更换验证码

                            break;

                        case 2:
                            //姓名错误
                            warningMessage(this.LS.siteFormSubmitIng, true);
                            break;

                        case 3:
                            //评论错误
                            warningMessage(this.LS.commentError, true);
                            break;

                        case 4:
                            //提交成功
                            //Fai.top.location.reload();
                            if (showAfterReview === false) {
                                successMessage(this.LS.commentSuccess, true);
                            } else {
                                successMessage(this.LS.commentSuccessNeedReview, true);
                            }

                            // 提交后异步刷新加载第一页评论
                            if (_this.$refs.productComment[0]) {
                                _this.$refs.productComment[0].getCommentInfoAjax(1, function () {
                                    // 数据清空为默认
                                    $('.evaluate_nick_name').length > 0 && $('.evaluate_nick_name').val(''); // 清空昵称

                                    $('.evaluate_text').length > 0 && $('.evaluate_text').val(''); // 清空内容

                                    $('.evaluate_auth_code').length > 0 && $('.evaluate_auth_code').val(''); // 清空验证码

                                    $('.evaluate_code_img').length > 0 && $('.evaluate_code_img').click(); // 更换验证码

                                    $('.evaluate_upload_pic_lists').length > 0 &&
                                        $('.evaluate_upload_pic_lists').empty(); // 清空上传图片列表
                                }); // 评论成功后隐藏评论面板

                                $('.detail_evaluate_panel').length > 0 && $('.detail_evaluate_panel').hide();
                                $('body').removeClass('jz_body_no_scroll'); // 移除禁止滚动类名
                            }

                            break;

                        case 5:
                            //参数错误，请重新填写
                            warningMessage(this.LS.paramError, true);
                            break;

                        case 6:
                            //评论仅对会员开通，请登录。
                            warningMessage(
                                Fai.format(
                                    this.LS.commentOnlyMember,
                                    '<a href="' +
                                        preprocessUrl +
                                        'login.jsp?url=' +
                                        Fai.encodeUrl(window.location.href) +
                                        '">' +
                                        Fai.encodeHtml(this.LS.login) +
                                        '</a>'
                                ),
                                true
                            );
                            break;

                        case 7:
                            //评论功能关闭。
                            warningMessage(this.LS.commentClosed, true);
                            break;

                        case 8:
                            //系统错误
                            warningMessage(this.LS.commentSubmitError, true);
                            break;

                        case 9:
                            //评论数据已超过总数量限制。
                            warningMessage(this.LS.commentCountLimit, true);
                            break;
                        case 10:
                            // 系统维护中
                            warningMessage(this.LS.commentMaintainIng, true);
                            break;
                        default:
                    }

                    if (data.hasFW) {
                        $('.commit_tip').text(data.msg).show();
                    }
                })
                .catch(() => {
                    warningMessage(this.LS.js_systemError, true);
                    $('#evaluate_code_img').click();
                });
        },

        calculateFixed() {
            if (this.device == 'mobi') {
                this.fixedPosition =
                    $('.product_detail_wrap').outerHeight(true) + $('.product_detail_wrap').offset()?.top;

                if (this.isPayProduct) {
                    this.fixedPosition = $('.pay_product_wrap').outerHeight(true) + $('.pay_product_wrap').offset().top;
                }
            }
        },

        sitelog() {
            let styleNum = this.module.prop0;
            logDog(200877, styleNum + 2);
        },

        ueHoverImageFunc() {
            typeof JZ !== 'undefined' &&
                this.$nextTick(() => {
                    // ue换图事件
                    ueHoverStyle();
                    ueHoverChangeImage();
                });
        },

        showMobiMsgWrap() {
            if (this.device != 'mobi') {
                return;
            }
            //需要超过电梯导航
            $(`#module${this.moduleId}`).css('zIndex', 10);

            $(this.$refs.mobiMsgBigWrap).show();
            $(this.$refs.mobiMsgWrap).animate(
                {
                    height: '80%',
                },
                500
            );
            $(this.$refs.mobiMsgBg).animate(
                {
                    height: '20%',
                },
                500
            );

            if (!this.manageMode) {
                if (this.topIndex == 0) {
                    this.x = $(document).scrollTop();
                    $('body').css({
                        position: 'fixed',
                        top: `-${this.x}px`,
                    });
                }

                this.topIndex = 1;
            }
        },

        hideMobiMsgWrap(e) {
            e.stopPropagation();
            $(`#module${this.moduleId}`).css('zIndex', '');
            $(this.$refs.mobiMsgWrap).animate(
                {
                    height: '0',
                },
                500,
                () => {
                    $(this.$refs.mobiMsgBigWrap).hide();
                }
            );
            $(this.$refs.mobiMsgBg).animate(
                {
                    height: '100%',
                },
                500
            );

            if (!this.manageMode) {
                $('body').css({
                    position: 'static',
                });
                $(document).scrollTop(this.x);
                this.topIndex = 0;
            }
        },

        initRealNameAuthTips() {
            if (this.manageMode === false && this.realNameAuth.allowShowMessage === false) {
                topTips.addTopTips(realNameAuthTips);
            }
        },

        getActiveImagePath() {
            const styleType = this.module.prop0;
            if (styleType === 0) {
                const activeImg = $('.module_product_detail_content .detail_big_pic_content').find('img:visible').eq(0);
                return activeImg.attr('src');
            }
            if (styleType === 2) {
                return this.picPathList[this.activeIndex]?.path ?? '';
            }
            return '';
        },

        shareToPinterest() {
            if (!this.shareToService) {
                this.shareToService = ServiceFactory.get('ShareTo', {
                    params: [Fai.ing],
                });
            }

            this.shareToService.pinterest('one', {
                url: window.location.href,
                media: this.getActiveImagePath(),
            });
        },

        downloadClick() {
            let manageMode = window.bizShared.context.isManageMode();
            fileDownloadLimitHandler();
            window.bizShared.context.fdpLog('jz_product_file_click', {
                jz_content_terminal: '自适应',
                jz_free_text1: manageMode ? '管理态' : '访客态',
                jz_free_text2: '产品详情页',
                jz_version: window.bizShared.context.jzVersion,
            });

            if (!manageMode) {
                if (this.downloadAuth.isNeedLogin === true) {
                    Fai.ing(LS.attachLoginTips, true, 3000);
                    createLoginPopup();
                    return;
                }
                if (this.downloadAuth.currentMemberPdAttachAllow == false) {
                    warningMessage(Fai.format(LS.site_file_down_group_tip, this.memberName), true);
                    return;
                }
            }
        },
    },
};
